.header .nav-link {
  color: #4f2d8b !important;
}

.header {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  grid-area: header;
  display: flex;
  padding: 0 2px;
  align-items: center;
  min-height: 60px;
  position: sticky;
  max-width: 100%;
  top: 0;
  background-color: white;
  z-index: 999;
  margin-left: 260px;
}

.header a:visited {
  text-decoration: none !important;
}

.header a {
  text-decoration: none !important;
}

.header li {
  margin-right: 10px;
}
