.sm-gutters {
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.c-pointer {
  cursor: pointer;
}

.w-25 {
  width: 25%;
  min-width: 250px;

  @media screen and (max-width: 767px) {
    width: 95% !important;
    margin-top: 10px;
  }
}
