.text-small {
  font-size: 8pt;
  letter-spacing: 0.05px;
}

.divider {
  color: hsla(210, 12%, 12%, 0.1);
}

.footer-text {
  padding: 0.375rem 0.75rem;
}
