@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&display=swap&subset=cyrillic');

@import 'reset';
@import 'utils';
@import 'main';

.main-container {
  margin-left: 260px;
  padding: 20px;
}

.sidebar {
  position: fixed;
  max-width: 260px;
  width: 100%;
  color: #fff;
  background-color: #1f2937;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}

.sidebar img {
  margin: 20px auto 0;
}

.sidebar-menu {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: column !important;
  padding-top: 1rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.sidebar-menu li {
  margin-bottom: 0.2rem;
}

.sidebar-menu a {
  display: block;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  padding: 0.55rem 0.75rem;
  border-radius: 0.5rem;
  color: #fff;
  text-decoration: none;
}

.sidebar-menu a:hover {
  color: #f2f4f6;
  background-color: #374151;
}
