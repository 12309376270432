.auth-page-logo {
  width: 90%;
  margin: 20px auto;
}

.page-title {
  color: #262828;

  margin-bottom: 2rem;
}

.auth-card {
  border: 1px solid #fff5f7;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(250, 77, 106, 0.12);
  border-radius: 2px;
  background-color: #fff;
  padding: 31px 28px 38px 28px;
  border-radius: 8px;
}

.auth-card .form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.auth-card .form-text {
  margin-left: 1rem;
}

.auth-card .mb-3 {
  margin-bottom: 2rem !important;
}

.sign-up-link-in-login-form {
  color: #9b9a9a;
}

.sign-up-link-in-login-form a {
  color: #8a53c9;
}
.auth-card .forget-password-block {
  font-size: small;
  text-align: right;
}

.auth-card .forget-password-block a {
  color: #8a53c9;
}

.auth-card .btn-auth-action {
  color: #fff;
  background-color: #8a53c9;
  border-color: #8a53c9;
  height: 3rem;
}

.auth-card input[type='email'],
input[type='text'],
input[type='password'],
select {
  height: 2.45rem;
}

select {
  background-color: #e5eefe !important;
}
.auth-card .captcha-field {
  display: inline-block;
  vertical-align: middle;
}

.auth-card .captcha-description {
  display: block;
  font-size: smaller;
  margin-bottom: 1rem;
  text-align: center;
}
