.quizboard-container {
  width: 1100px;
  margin: 0 auto;
}

button {
  border: none;
  color: #fff;
  padding: 4px;
  background-color: green;
}
.btn-add-answer {
  background-color: green;
  margin-right: 4px;
}
.answer-field {
  margin-right: 4px;
}
textarea {
  resize: both;
}
