.quizboard-container {
  width: 68rem;
  margin: 0 auto;
}

.quiz-form {
  display: flex !important;
  margin-top: 20px;

  input,
  select {
    height: 3rem !important;
  }

  input[name='name'],
  select[name='group'],
  select[name='questionType'],
  input[name='consumer_key'],
  input[name='consumer_secret'],
  input[name='consumer_url'] {
    background-color: #fff !important;
    width: 700px;
  }

  input[name='consumer_key'],
  input[name='consumer_secret'] {
    margin-left: -28px !important;
  }
  input[name='consumer_url'] {
    margin-left: -15px !important;
  }
  .consumer-secret {
    position: relative;
    top: 12px;
    right: 30px;
    background: transparent !important;
    color: #000;
    cursor: pointer;

    .copy-url {
      position: absolute;
      top: 16px;
      left: -12px;
      font-size: 12px;
    }
  }

  input[name='pass_score'],
  input[name='deadline_in_minutes'] {
    width: 200px;
  }

  label {
    margin-right: 90px;
    margin-left: 20px;
    margin-top: 8px;
  }

  .show-form-error {
    color: red;
    max-width: 200px;
  }

  .hide-form-error {
    visibility: hidden !important;
  }
}
.correct-answer input,
.correct-answer label {
  margin-right: 10px;
}
.passScore label {
  margin-right: 50px !important;
}

.quiz-buttons {
  margin-top: 40px;
  margin-left: 20px;

  button[type='submit'] {
    margin-right: 20px;
  }
}
.question-btn {
  margin-left: 0;
  margin-top: 30px;
  border-top: 1px solid #ced4da;
  button {
    margin-right: 10px;
    margin-top: 20px;
  }
}
.question-form label {
  margin-left: 0;
}
textarea {
  resize: both;
}
.markdown {
  width: 40rem;
  height: 15.75rem;
  margin-left: 20px;
}
.md-answer {
  height: 9.85rem;
}
.no-question {
  margin-top: -55px;
}
pre {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem;
  width: 70%;
}
.hljs-number {
  color: #86b7fe;
}
